.App:focus{
    border-color:transparent;
    outline: none;
}
body {
    font-family: Verdana, sans-serif;

}

a{
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
.home{ 
    min-height: 100vh;
    min-width: 100vw;
    display: grid; 
    align-items: center;
    justify-items: center;
}
.publications, .education, .skills, .hobbies, .projects, .externallinks, .experience, .about{ 
    text-align: left;
}
.optionsWrapper{
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // width: 120vw;
    // left: -10vw;

    // position: fixed;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // width: 100%;
    transform: translate(0%, 0%) scale(1);
    transition: 0.5s transform;
    /* position: fixed; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    /* height: 90%; */
    /* left: 10%; */
    /* top: 10%; */
    padding: 1% 1%;
    .optionsPanel{
        display: grid;
        grid-gap: 5%;
        list-style: none;
        padding:0;
        max-width: 1100px;
        width: 100%;
        // width: 90vw;
        // height: 90vh;
        // margin: 5vh 5vw;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: 1fr;
        align-items:center;
        >li{
            display: block;
            position: relative; 
            .item{
                border:2px solid orange;
                border-radius: 15px;
                padding: 50px; 
                margin: 0 auto!important;
                background-color: #0000004f;
                color: white;
                cursor: pointer;
                font-size: 1.3em; 
                font-family: Verdana, sans-serif;
                font-weight: bold;
                outline: 0px solid white;
                transition: outline 0.4s, transform 0.4s;
                &[option='active']{
                    outline: 6px solid orange;
                    box-shadow: 0px 0px 15px black;
                    transform: scale(1.1);
                    transition: outline 1.5s, transform 1s, box-shadow 1s;
                }
            } 
        }
    }
    .instructions{
        padding: 30px 20px;
        max-width: 70%;
        color: #565656;
        text-shadow: 0px 1px 21px #575757;
    }

    $horizontal: 25%;
    $vertical: 20%;
    
    &[dir='tl']{
        transform: translate($horizontal, $vertical) scale(1.2);
    }
    &[dir='t']{
        transform: translate(0%, $vertical) scale(1.2);
    }
    &[dir='tr']{    
        transform: translate(- $horizontal, $vertical) scale(1.2);
    }
    &[dir='ml']{
        transform: translate($horizontal, 0%) scale(1.2);
    }
    &[dir='m']{
        
    }
    &[dir='mr']{
        transform: translate(-$horizontal, 0%) scale(1.2);        
    }
    &[dir='bl']{
        transform: translate($horizontal, -$vertical) scale(1.2);        
    }
    &[dir='b']{
        transform: translate(0%, -$vertical) scale(1.2);        
    }
    &[dir='br']{
        transform: translate(-$horizontal, -$vertical) scale(1.2);        
    }
    
}
// 3:2, 5:9, 4:3, 16:9, 1:1

/* Minimum aspect ratio */
@media (min-aspect-ratio: 8/5) {
    .optionsPanel {
        aspect-ratio: 5/3;
    }
  }
  
  /* Maximum aspect ratio */
@media (max-aspect-ratio: 3/2) {
    .optionsPanel {
        aspect-ratio: 3/2;
    }
}
  
  /* Exact aspect ratio, put it at the bottom to avoid override*/
@media (aspect-ratio: 1/1) {
    .optionsPanel {
        aspect-ratio: 5/4;
    }
}
