body *{
    box-sizing: border-box;
}
.navBlock{
    >ul{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        aspect-ratio: 1/1;
        list-style: none;
        padding: 0;
        width: 80%;
        margin: 0 auto;
        >li{
            display: flex;
            align-items: center;
            justify-content: center;
            $navcolor: #5a84b9f7;
            span{
                border-left: 0.5em solid #5a84b9f7;
                border-top: 0.5em solid #5a84b9f7;
                display: block;
                width: 40px;
                height: 40px;
                cursor: pointer;
                cursor: pointer;
            }
            span[status='true']{
                border-color:#ffcc70;
            }
            span:hover{
                border-color: rgb(24, 183, 24);
            }
            .tl{
                transform: rotate(0deg);
            }
            .t{
                transform: rotate(45deg);
            }
            .tr{
                transform: rotate(90deg);
            }
            .ml{
                transform: rotate(315deg);
            }
            .m{
                outline:2px solid $navcolor;
                background-color: $navcolor;
                border:2px solid #ffcc70;
                border-radius: 200px;
                aspect-ratio: 1/1; 
                width: 30px;
                height: 30px;
                padding: 50px;
                position: absolute;
            }
            .mr{
                transform: rotate(135deg);
            }
            .bl{
                transform: rotate(270deg);
            }
            .b{
                transform: rotate(225deg);
            }
            .br{
                transform: rotate(180deg);
            }
        }
    }
}

.item p{
    width:0px;
    background-color: red;
    height:6px;
    text-align: left; 
    line-height: 40px;
    display: block;
    margin: 10px 0px;
    transition: width 0.6s, background-color 0.6s; 
    font-size: 0.7em;
    margin-bottom: -13px;
}
.item p.openingBar{
    width: 100%;
    background-color: green;
    transition: width 2s, background-color 2s;
    transition-timing-function: linear;
}