
body{
  background-image: url('../public/images/bg4.png');
  background-size: 50%;
  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background: rgb(168 164 202);
  background: linear-gradient(0deg, rgb(1 5 53 / 92%) 0%, rgb(4 29 80) 25%, rgb(6 239 252) 50%, rgb(19 53 84) 75%, rgb(19 43 77) 100%); */
}
.App {
  text-align: center;
  background-color: #ffffffad;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
